import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {UserSettingDocument} from './UserSettingDocument';

export const userSettingTypeDef: TypeDef<UserSettingDocument> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        creator: {
            type: 'string'
        },
        creatorName: {
            type: 'string'
        },
        expiresAt: {
            type: 'timestamp'
        },
        isDefault: {
            type: 'boolean'
        },
        name: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        removed: {
            type: 'float64'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        code: {
            type: 'string'
        },
        context: {} as never,
        created: {
            type: 'float64'
        },
        data: {
            additionalProperties: false,
            optionalProperties: {
                _id: {
                    type: 'string'
                },
                Conclusion: {
                    type: 'boolean'
                },
                'Inspection result': {
                    type: 'boolean'
                },
                Introduction: {
                    type: 'boolean'
                },
                NAItems: {
                    type: 'boolean'
                },
                'Not answered items': {
                    type: 'boolean'
                },
                'Report cover': {
                    type: 'boolean'
                },
                automatedExport: {
                    additionalProperties: false,
                    optionalProperties: {
                        docxEmail: {
                            type: 'string'
                        },
                        pdfEmail: {
                            type: 'string'
                        }
                    }
                },
                cbLang: {
                    additionalProperties: false,
                    optionalProperties: {
                        downloadAsPdf: {
                            type: 'string'
                        },
                        downloadAsWord: {
                            type: 'string'
                        }
                    },
                    properties: {
                        and: {
                            type: 'string'
                        },
                        between: {
                            type: 'string'
                        },
                        change: {
                            type: 'string'
                        },
                        changeLogo: {
                            type: 'string'
                        },
                        conclusion: {
                            type: 'string'
                        },
                        date: {
                            type: 'string'
                        },
                        download: {
                            type: 'string'
                        },
                        general: {
                            additionalProperties: false,
                            optionalProperties: {
                                date: {
                                    type: 'string'
                                },
                                deleted: {
                                    type: 'string'
                                },
                                edit: {
                                    type: 'string'
                                },
                                expertFeatureDescr: {
                                    type: 'string'
                                },
                                filterDateRange: {
                                    type: 'string'
                                },
                                maximumMBs: {
                                    type: 'string'
                                },
                                maximumMBsText: {
                                    type: 'string'
                                },
                                maybeLater: {
                                    type: 'string'
                                },
                                no: {
                                    type: 'string'
                                },
                                noInternetHeader: {
                                    type: 'string'
                                },
                                noInternetText: {
                                    type: 'string'
                                },
                                note: {
                                    type: 'string'
                                },
                                search: {
                                    type: 'string'
                                },
                                selectAll: {
                                    type: 'string'
                                },
                                tasks: {
                                    type: 'string'
                                },
                                update: {
                                    type: 'string'
                                },
                                updateAvailable: {
                                    type: 'string'
                                },
                                yes: {
                                    type: 'string'
                                }
                            },
                            properties: {
                                apply: {
                                    type: 'string'
                                },
                                back: {
                                    type: 'string'
                                },
                                cancel: {
                                    type: 'string'
                                },
                                category: {
                                    additionalProperties: false,
                                    properties: {
                                        aviation: {
                                            type: 'string'
                                        },
                                        construction: {
                                            type: 'string'
                                        },
                                        engineering: {
                                            type: 'string'
                                        },
                                        food: {
                                            type: 'string'
                                        },
                                        healthcare: {
                                            type: 'string'
                                        },
                                        hospitality: {
                                            type: 'string'
                                        },
                                        manufactoring: {
                                            type: 'string'
                                        },
                                        mining: {
                                            type: 'string'
                                        },
                                        other: {
                                            type: 'string'
                                        },
                                        retail: {
                                            type: 'string'
                                        },
                                        transport: {
                                            type: 'string'
                                        }
                                    }
                                },
                                comingSoon: {
                                    type: 'string'
                                },
                                comingSoonHeader: {
                                    type: 'string'
                                },
                                confirm: {
                                    type: 'string'
                                },
                                contact: {
                                    type: 'string'
                                },
                                day: {
                                    type: 'string'
                                },
                                dayLeftDescr: {
                                    type: 'string'
                                },
                                daysLeft: {
                                    type: 'string'
                                },
                                done: {
                                    type: 'string'
                                },
                                email: {
                                    type: 'string'
                                },
                                error: {
                                    type: 'string'
                                },
                                gotIt: {
                                    type: 'string'
                                },
                                inspection: {
                                    type: 'string'
                                },
                                inspections: {
                                    type: 'string'
                                },
                                isNotAvailableOffline: {
                                    type: 'string'
                                },
                                location: {
                                    type: 'string'
                                },
                                locations: {
                                    type: 'string'
                                },
                                locationsMaxReached: {
                                    type: 'string'
                                },
                                logoutWarning: {
                                    type: 'string'
                                },
                                month: {
                                    type: 'string'
                                },
                                name: {
                                    type: 'string'
                                },
                                noCorrectRole: {
                                    type: 'string'
                                },
                                offlineDelete: {
                                    type: 'string'
                                },
                                ok: {
                                    type: 'string'
                                },
                                organisation: {
                                    type: 'string'
                                },
                                organisations: {
                                    type: 'string'
                                },
                                paidFeature: {
                                    type: 'string'
                                },
                                paidFeatureAlert: {
                                    type: 'string'
                                },
                                password: {
                                    type: 'string'
                                },
                                proFeature: {
                                    type: 'string'
                                },
                                proFeatureDescr: {
                                    type: 'string'
                                },
                                requestAccess: {
                                    type: 'string'
                                },
                                requiredField: {
                                    type: 'string'
                                },
                                save: {
                                    type: 'string'
                                },
                                sorts: {
                                    additionalProperties: false,
                                    properties: {
                                        category: {
                                            type: 'string'
                                        },
                                        creator: {
                                            type: 'string'
                                        },
                                        downloads: {
                                            type: 'string'
                                        },
                                        newest: {
                                            type: 'string'
                                        },
                                        oldest: {
                                            type: 'string'
                                        },
                                        templateName: {
                                            type: 'string'
                                        }
                                    }
                                },
                                template: {
                                    type: 'string'
                                },
                                templates: {
                                    type: 'string'
                                },
                                templatesMaxReached: {
                                    type: 'string'
                                },
                                upgrade: {
                                    type: 'string'
                                },
                                user: {
                                    type: 'string'
                                },
                                users: {
                                    type: 'string'
                                },
                                view: {
                                    type: 'string'
                                },
                                warning: {
                                    type: 'string'
                                },
                                year: {
                                    type: 'string'
                                }
                            }
                        },
                        input: {
                            type: 'string'
                        },
                        insertedValue: {
                            type: 'string'
                        },
                        inspectionResult: {
                            type: 'string'
                        },
                        inspectorName: {
                            type: 'string'
                        },
                        introduction: {
                            type: 'string'
                        },
                        items: {
                            type: 'string'
                        },
                        name: {
                            type: 'string'
                        },
                        operator: {
                            additionalProperties: false,
                            properties: {
                                equal: {
                                    type: 'string'
                                },
                                larger: {
                                    type: 'string'
                                },
                                largerEqual: {
                                    type: 'string'
                                },
                                smaller: {
                                    type: 'string'
                                },
                                smallerEqual: {
                                    type: 'string'
                                }
                            }
                        },
                        optionChosen: {
                            type: 'string'
                        },
                        range: {
                            type: 'string'
                        },
                        rated: {
                            type: 'string'
                        },
                        remark: {
                            type: 'string'
                        },
                        share: {
                            type: 'string'
                        },
                        signature: {
                            type: 'string'
                        },
                        standardRaport: {
                            type: 'string'
                        },
                        stars: {
                            type: 'string'
                        },
                        valid: {
                            type: 'string'
                        },
                        value: {
                            type: 'string'
                        },
                        valueSmall: {
                            type: 'string'
                        }
                    }
                },
                characterSet: {
                    enum: ['latin', 'cyrillic', 'chinese simplified']
                },
                codeID: {
                    type: 'string'
                },
                conclusion: {
                    type: 'boolean'
                },
                conclusionLabel: {
                    type: 'string'
                },
                conductedByLabel: {
                    type: 'string'
                },
                correctiveActionLabel: {
                    type: 'string'
                },
                cover: {
                    type: 'boolean'
                },
                dateLabel: {
                    type: 'string'
                },
                dateSolvedLabel: {
                    type: 'string'
                },
                docxEmail: {
                    type: 'string'
                },
                docxLanguage: {
                    type: 'string'
                },
                emptyLabel: {
                    type: 'string'
                },
                favouritesOnly: {
                    type: 'boolean'
                },
                fontColor: {
                    type: 'string'
                },
                footerText: {
                    type: 'string'
                },
                headerLogo: {
                    type: 'string'
                },
                headingRepeat: {
                    type: 'boolean'
                },
                inspectionResultLabel: {
                    type: 'string'
                },
                introduction: {
                    type: 'boolean'
                },
                introductionLabel: {
                    type: 'string'
                },
                invalidItems: {
                    type: 'boolean'
                },
                locationLabel: {
                    type: 'string'
                },
                locationSlice: {
                    type: 'float64'
                },
                noLabel: {
                    type: 'string'
                },
                notApplicableLabel: {
                    type: 'string'
                },
                pdfEmail: {
                    type: 'string'
                },
                pdfLanguage: {
                    type: 'string'
                },
                photoColorDepth: {
                    type: 'float64'
                },
                photoResolution: {
                    type: 'float64'
                },
                reportTitleLabel: {
                    type: 'string'
                },
                reservedSpaceStyle: {
                    enum: ['none', 'space', 'table']
                },
                scorePersection: {
                    type: 'boolean'
                },
                scoreType: {
                    type: 'string'
                },
                showGeneratedByFooter: {
                    type: 'boolean'
                },
                showPhotos: {
                    type: 'boolean'
                },
                signatureLabel: {
                    type: 'string'
                },
                spacerAvailableTextLines: {
                    type: 'uint32'
                },
                yesLabel: {
                    type: 'string'
                }
            },
            properties: {
                fontFamily: {
                    type: 'string'
                },
                fontSize: {
                    type: 'string'
                },
                fontTypes: {
                    elements: {
                        additionalProperties: false,
                        optionalProperties: {
                            selected: {
                                type: 'boolean'
                            }
                        },
                        properties: {
                            text: {
                                type: 'string'
                            },
                            value: {
                                type: 'string'
                            }
                        }
                    }
                },
                headerColor: {
                    type: 'string'
                },
                headerSize: {
                    type: 'string'
                },
                includeSections: {
                    additionalProperties: false,
                    properties: {
                        NAItems: {
                            type: 'boolean'
                        },
                        conclusion: {
                            type: 'boolean'
                        },
                        cover: {
                            type: 'boolean'
                        },
                        inspectionResult: {
                            type: 'boolean'
                        },
                        introduction: {
                            type: 'boolean'
                        },
                        invalidItems: {
                            type: 'boolean'
                        },
                        openItems: {
                            type: 'boolean'
                        },
                        scorePersection: {
                            type: 'boolean'
                        },
                        validItems: {
                            type: 'boolean'
                        }
                    }
                },
                inspectionResult: {
                    type: 'boolean'
                },
                introText: {
                    type: 'string'
                },
                invaridItems: {
                    additionalProperties: false,
                    properties: {
                        value: {
                            type: 'boolean'
                        }
                    }
                },
                labels: {
                    additionalProperties: false,
                    properties: {
                        date: {
                            type: 'string'
                        },
                        inspectionResult: {
                            type: 'string'
                        },
                        inspectorName: {
                            type: 'string'
                        },
                        introduction: {
                            type: 'string'
                        },
                        item: {
                            type: 'string'
                        },
                        points: {
                            type: 'string'
                        },
                        score: {
                            type: 'string'
                        },
                        scorePersection: {
                            type: 'string'
                        }
                    }
                },
                logo: {
                    type: 'string'
                },
                openItems: {
                    type: 'boolean'
                },
                reportNA: {
                    type: 'string'
                },
                reportNotOk: {
                    type: 'string'
                },
                reportOk: {
                    type: 'string'
                },
                reportOpen: {
                    type: 'string'
                },
                reportVariables: {
                    additionalProperties: false,
                    properties: {
                        created: {
                            type: 'string'
                        },
                        inspectionScore: {
                            type: 'string'
                        },
                        inspectorEmail: {
                            type: 'string'
                        },
                        inspectorName: {
                            type: 'string'
                        },
                        name: {
                            type: 'string'
                        },
                        templateName: {
                            type: 'string'
                        },
                        updated: {
                            type: 'string'
                        }
                    }
                },
                score: {
                    additionalProperties: false,
                    properties: {
                        value: {
                            type: 'boolean'
                        }
                    }
                },
                scoreNA: {
                    type: 'string'
                },
                scoreNotOk: {
                    type: 'string'
                },
                scoreOk: {
                    type: 'string'
                },
                scoreOpen: {
                    type: 'string'
                },
                validItems: {
                    type: 'boolean'
                }
            }
        },
        type: {
            type: 'string'
        }
    }
};
